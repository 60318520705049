import React from 'react';
import { Heading } from 'rebass';
import Logo from '../Utils/Logo';
import { Section } from '../../utils/components';

interface Props {
  className?: string;
  bg?: string;
  color?: string;
}

export default function Footer({ className, bg, color }: Props) {
  return (
    <Section as={'footer'} className={className} bg={bg || 'grey'}>
      <Heading fontSize={6}>
        <Logo color={color || 'white'} withMd={true} />
      </Heading>
    </Section>
  );
}
