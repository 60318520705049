import { borders, color, fontSize, space, width } from 'styled-system';

import { Link as GatsbyLink } from 'gatsby';
import React from 'react';
import { Link as RebassNavLink } from 'rebass';
import styled from 'styled-components';

const StyledGatsbyLink = styled(GatsbyLink)`
    color: inherit;
    display: inline-flex;
    align-items: center;
    padding: 8px 7px;
    cursor: pointer;
    white-space: nowrap;
    align-self: stretch;
    font-size: 14px;
    font-weight: bold;

    ${borders}
    ${space}
    ${width}
    ${fontSize}
    ${color}
`;

export default function NavLink({ to, ...props }) {
    const isInternalLink = /^\/(?!\/)/.test(to);
    if (isInternalLink)
        return (
            <StyledGatsbyLink to={to} {...props} />
        );
    else
        return (
            <RebassNavLink href={to} {...props} />
        );
}
