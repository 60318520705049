import styled, { css } from 'styled-components';
import { Box, Flex as BaseFlex, Button as BaseButton } from 'rebass';
import { darken, lighten } from 'polished';

import React from 'react';
import { CONTAINER_MAX_WIDTH } from '../constants';
import { FunctionComponent } from 'react';
import { media } from './theme';

export const Flex = styled(BaseFlex)`
  & > .flex {
    flex: 1;
  }
`;

export const Container = styled(Box)`
  max-width: ${CONTAINER_MAX_WIDTH};
  margin-left: auto;
  margin-right: auto;
  padding-left: 16px;
  padding-right: 16px;
  ${props =>
    props.height &&
    css`
      height: ${props.height};
    `}
`;

export const NDContainer = styled(Container)`
  max-width: 100%;
`;

export const Content = styled(Box)`
  margin: 0 auto;
  max-width: ${props => props.maxWidth || '960px'};
`;

export const Measure = styled(Box)`
  max-width: ${props => props.maxWidth || '21em'};
`;

export const Lead = styled(Box)`
  line-height: 1.25;
`;

export const Button = styled(BaseButton).attrs({
  type: 'button',
  fontSize: 1
})`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: border-color, background-color 0.1s ease-in-out;
  border-radius: ${props => props.borderRadius || '5px'};
  border: 2px solid transparent;

  &[disabled] {
    opacity: 0.5;
    cursor: default;
  }

  &:focus {
    outline: 0;
  }
`;

export const BrandSolidButton = styled(Button)`
  background-color: ${props => props.theme.colors.orange};
  color: ${props => props.theme.colors.white};

  &:not(:disabled) {
    &:hover {
      border-color: ${props => props.theme.lightRed};
      background-color: ${props => props.theme.colors.lightRed};
    }
  }
`;

export const BrandPlainButton = styled(Button)`
  background-color: transparent;
  color: ${props => props.theme.colors.orange};
  border-color: ${props => props.theme.colors.orange};

  &:not(:disabled) {
    &:hover {
      border-color: ${props => props.theme.lightRed};
      background-color: ${props => props.theme.colors.lightYellow};
    }
  }
`;

export const SuccessSolidButton = styled(Button)`
  background-color: ${props => props.theme.colors.green};
  &:hover {
    background-color: ${props => lighten(0.1, props.theme.colors.green)};
  }
  &:active {
    background-color: ${props => darken(0.1, props.theme.colors.green)};
  }
`;

export const NoScrollBar = () => `
    &::-webkit-scrollbar {
        width: 0;
    }
`;

const StyledSection = styled(Box).attrs({ p: 5 })`
  ${media.lessThan('md')`
        padding: 64px 30px;
    `};
`;

export interface SectionProps {
  bg?: string;
  as?: keyof JSX.IntrinsicElements;
  className?: string;
}
export const Section: FunctionComponent<SectionProps> = ({
  children,
  as: asProp,
  ...props
}) => {
  return (
    <StyledSection as={asProp || 'section'} {...props}>
      <Container>{children}</Container>
    </StyledSection>
  );
};
