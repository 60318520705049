import NavLink from './NavLink';
import React from 'react';
import { Text } from 'rebass';
import styled from 'styled-components';
import MdLogo from '../../assets/images/md.svg';

interface Props {
    className?: string;
    color?: string;
    byMd?: boolean;
    withMd?: boolean;
}

const Container = styled.div`
  display: inline-block;
`;

const MadeBy = styled(Text).attrs({
    fontWeight: 'normal',
    fontSize: 1
})`
    font-family: Roboto, sans-serif;
    text-align: right;
    ${(props) => props.relative && `
        position: relative;
        top: -18px;
        left: -3px;
    `}

    > * {
        display: inline-block;
        vertical-align: middle;
    }

    a {
        padding: 0;
    }

    svg {
        height: 1em;
        width: auto;
        display: inline-block;
        vertical-align: middle;
        * {
            fill: ${(props) => props.theme.colors[props.color]};
        }
    }
`;

export default function KitaExpertLogo({ className, color, withMd, byMd }: Props) {

    const colorOrOrange: string = color || 'orange';
    const colorOrYellow: string = color || 'yellow';

    return (
        <Container>
            <Text as={'span'} className={className} color={colorOrYellow} fontFamily='title'>Kita</Text>
            <Text as={'span'} color={colorOrOrange} fontFamily='title' className={className}>Expert</Text>
            {!byMd && withMd && (
                <MadeBy color={colorOrYellow} mr='2px'>
                    <NavLink to='https://makersden.io/'>
                        <MdLogo color={colorOrYellow} />
                    </NavLink>
                </MadeBy>
            )}
            {byMd && !withMd && (
                <MadeBy relative={true} color={colorOrYellow}>
                    <Text as='span' color={colorOrYellow}>by&nbsp;</Text>
                    <NavLink to='https://makersden.io/'>
                        <MdLogo color={colorOrYellow} />
                    </NavLink>
                </MadeBy>
            )}
        </Container>
    );
}
