import { generateMedia } from 'styled-media-query';
import { darken, lighten } from 'polished';

const theme = {
  colors: {
    black: '#000000',
    mute: '#777',
    grey: 'rgba(0, 0, 0, 0.59)',
    orange: '#FF8A00',
    green: 'hsl(150, 100%, 30%)',
    red: '#FF4800',
    darkRed: '',
    lightRed: '',
    white: '#FFFFFF',
    yellow: '#FFC800',
    lightYellow: '',
    brand: '#FFC800',
    warning: '#FF4800'
  },

  fonts: {
    // -apple-system,'BlinkMacSystemFont','Segoe UI','Roboto','Oxygen','Ubuntu','Cantarell','Fira Sans','Droid Sans','Helvetica Neue',sans-serif
    sans: 'Roboto, sans-serif',
    title: 'Arvo, sans-serif'
  },

  fontSizes: [14, 16, 18, 20, 24, 32, 48, 64, 72]
};

theme.colors.darkRed = darken(0.2, theme.colors.red);
theme.colors.lightRed = lighten(0.2, theme.colors.red);
theme.colors.lightYellow = lighten(0.4, theme.colors.yellow);

export default theme;

export const media = generateMedia({
  xxl: '1440px',
  xl: '1200px',
  lg: '992px',
  md: '768px',
  sm: '576px'
});
