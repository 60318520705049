import React from 'react';
import { Helmet } from 'react-helmet';
import { createGlobalStyle } from 'styled-components';
import theme from '../../utils/theme';

const GlobalStyle = createGlobalStyle`
    html, body, ::before, ::after {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
    }

    body {
        line-height: 1.25;
        font-size: 0.88em;
    }

    h1, h2, h3, h4, h5 {
        font-family: Arvo, sans-serif;
    }

    .active-link {
        position: relative;

        &::after {
            position: absolute;
            content: '';
            display: block;
            top: 55px;
            width: 40px;
            border-bottom: 2px solid red;
        }
    }

    .marker-cluster-custom {
        display: flex !important;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .kita-count {
        margin: 0;
        padding: 0;
    }

    .leaflet-marker-icon {
        outline: none;
    }

	.pulse {
        position: absolute;
        bottom: 50%;
        left: 50%;
		width: 30px;
		height: 30px;
		border: 10px solid ${theme.colors.red};
		border-radius: 30px;
        background: transparent;
        animation: pulse 2s ease-out infinite;
	}

    @keyframes pulse {
        0% {
            transform: scale(0);
            opacity: 0.0;
        }
        50% {
            transform: scale(0.5);
            opacity: 0.7;
        }
        100% {
            transform: scale(1);
            opacity: 0.0;
        }
    }
`;

export default function Meta() {
  return (
    <div>
      <Helmet>
        <title>KitaExpert</title>
      </Helmet>
      <GlobalStyle />
    </div>
  );
}
