import React from 'react';

import styled, { ThemeProvider } from 'styled-components';
import { Box } from 'rebass';

import Header from '../components/Includes/Header';
import theme from '../utils/theme';
import Footer from '../components/Includes/Footer';
import Meta from '../components/Includes/Meta';

const Wrapper = styled.div``;

const Main = styled(Box)`
  min-height: calc(100vh - 80px);
  margin-top: 80px;
`;

export type HeaderType = 'full' | 'constrained';

export interface PageOptions {
  headerType?: HeaderType;
  showFooter?: boolean;
}

export const createPage = (
  Page: React.FunctionComponent<any>,
  pageOptions: PageOptions = {}
) => {
  const showFooter =
    typeof pageOptions.showFooter !== 'undefined'
      ? pageOptions.showFooter
      : true;
  return props => {
    return (
      <ThemeProvider theme={theme}>
        <Wrapper>
          <Meta />
          <Header headerType={pageOptions.headerType} />
          <Main>
            <Page {...props} />
          </Main>
          {showFooter && <Footer />}
        </Wrapper>
      </ThemeProvider>
    );
  };
};
