import React, { FunctionComponent } from 'react';
import { Flex } from 'rebass';
import styled, { css } from 'styled-components';

import Bear from '../../assets/bear.svg';
// import Locale from '../../assets/images/locale.png';
import KitaExpertLogo from '../Utils/Logo';
import NavLink from '../Utils/NavLink';
import { Container, NDContainer } from '../../utils/components';
import { MDLogo } from '../Utils/MdLogo';
import { MAKERSDEN_WEBSITE } from '../../constants';
import { media } from '../../utils/theme';

const StyledNavLink = styled(NavLink)<{ msHideOnly }>`
  ${props =>
    props.msHideOnly &&
    css`
      ${media.lessThan('md')`
      display: none;
    `}
    `}
`;

const StyledHeader = styled.div`
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 3;
  background-color: ${props => props.theme.colors.white};
  padding: 0 5px;
  height: 80px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
`;

const flexCcontainerCss = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
`;

const ConstrainedContainer = styled(Container)`
  ${flexCcontainerCss}
`;

const NonConstrainedContainer = styled(NDContainer)`
  ${flexCcontainerCss}
`;

const StyledBear = styled(Bear)`
  height: 64px;
  width: 70px;
`;

const StyledKitaExpertLogo = styled(KitaExpertLogo)`
  position: relative;
  left: -12px;
  font-family: Arvo, sans-serif;
`;

const StyledLogoLink = styled(NavLink)`
  padding-left: 0px;
  margin-left: -7px;
`;

const HeaderLogo = () => (
  <StyledLogoLink to='/'>
    <StyledBear />
    <StyledKitaExpertLogo />
  </StyledLogoLink>
);

const StyledMDLogo = styled(MDLogo)`
  margin: 0;
`;

export type HeaderType = 'full' | 'constrained';

export interface HeaderProps {
  headerType?: HeaderType;
}

const Header: FunctionComponent<HeaderProps> = ({
  headerType = 'constrained'
}) => {
  const Wrapper =
    headerType === 'constrained'
      ? ConstrainedContainer
      : NonConstrainedContainer;
  return (
    <StyledHeader>
      <Wrapper>
        <Flex alignItems='center'>
          <HeaderLogo />
          <StyledNavLink msHideOnly ml={'-12px'} to={'/'}>
            Home
          </StyledNavLink>
          <StyledNavLink msHideOnly to={'/search/'}>
            Search
          </StyledNavLink>
        </Flex>
        <Flex alignItems='center'>
          <NavLink to={MAKERSDEN_WEBSITE}>
            <StyledMDLogo size={'small'} />
          </NavLink>
        </Flex>
      </Wrapper>
    </StyledHeader>
  );
};

export default Header;
